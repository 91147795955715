import React from 'react';


import mapImg from '../../assets/images/contact/1.jpg'

const Map = (props) => {
    return (
        <div class="react-contacts pt-106"> 
            <div class="react-image-maping">
                <img src={mapImg} alt="Map" />
                {/* <iframe title='Ensate Location Map' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d126047.38948840182!2d76.65008388408692!3d9.156193291164369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1730184984016!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <div class="react-ripple react-tooltip1"><div class="box"><span>Adoor</span></div></div>
            </div>
        </div> 
    );

}

export default Map;