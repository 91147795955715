import React from 'react';


import infoImg1 from '../../assets/images/contact/2.png'
import infoImg2 from '../../assets/images/contact/3.png'
import infoImg3 from '../../assets/images/contact/4.png'

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <ul className="address-sec">
            <li>
                <em className="icon"><img src={infoImg1} alt="image" /></em>
                <span className="text"><em>Address</em> II Floor,Nakshathra Building,K C S M <br/> Kottapuram-Anandapally Road,Adoor,Pathanamthitta ,691523</span>
            </li>
            <li>
                <em className="icon"><img src={infoImg2} alt="image" /></em>
                <span className="text"><em>Contact</em> <a href="#">Mobile: (+91) 8075485492</a>(+91) 8281625492 <a href="#">Mail: contact@ensate.in</a></span>
            </li>
            <li>
                <em className="icon"><img src={infoImg3} alt="image" /></em>
                <span className="text"><em>Hours of operation</em> Monday - Friday: 08:00 a.m - 8.p.m <br/>Saturday & Sunday: 7 a.m - 10 p.m</span>
            </li>
        </ul>
    );

}

export default ContactInfo;